import { Suspense, useEffect, useState } from "react";
import PrivateRoute from "components/PrivateRoute/PrivateRoute";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import routes from "utils/routes";
import PublicRoutes from "components/PublicRoutes/PublicRoutes";
import "App.scss";
import Header from "components/Header/Header";
import Sidebar from "components/Sidebar/Sidebar";
import { Loading } from "components/Loading/Loading";

function App() {
  const [loading, setLoading] = useState(false);
  const token = localStorage.getItem("token");
  const [isAuth, setIsAuth] = useState(token ? true : false);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        if (token) {
          setIsAuth(true);
        }
      } catch (err) {
        console.error("Something went wrong. ");
      } finally {
        setLoading(false);
      }
    })();
  }, [token]);

  return (
    <div className="App">
      <BrowserRouter>
        <div className="app-content">
          {loading ? (
            <Loading />
          ) : (
            <Routes>
              {routes.map((route, i) => {
                const key = `route-${i}`;
                if (route.protected) {
                  return (
                    <Route
                      key={key}
                      path={route.path}
                      element={
                        <PrivateRoute
                          isAuth={isAuth}
                          path={route.path}
                          protect={true}
                        >
                          <Header />
                          <Sidebar />
                          <div className="main-layout">
                            <Suspense fallback={<Loading />}>
                              <route.component />
                            </Suspense>
                          </div>
                        </PrivateRoute>
                      }
                    />
                  );
                } else {
                  return (
                    <Route
                      key={key}
                      path={route.path}
                      element={
                        <PublicRoutes
                          isAuth={isAuth}
                          path={route.path}
                          protect={false}
                        >
                          <Suspense fallback={<Loading />}>
                            <route.component />
                          </Suspense>
                        </PublicRoutes>
                      }
                    />
                  );
                }
              })}
            </Routes>
          )}
        </div>
      </BrowserRouter>
    </div>
  );
}

export default App;
