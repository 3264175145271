import './Sidebar.scss';
import homeIcon from 'assets/images/home.svg';
import workIcon from 'assets/images/briefcase.svg';
import envelopeIcon from 'assets/images/envelope.svg';
import { useNavigate, useLocation } from 'react-router-dom';

const Sidebar = () => {
  const navigate = useNavigate();
  const params = useLocation();

  return (
    <div className='Sidebar'>
      <div
        className={`home-icon ${params.pathname === '/' ? 'active' : ''}`}
        onClick={() => navigate('/')}
      >
        <img src={homeIcon} alt='home' />
      </div>
      <div
        className={`work-icon ${
          params.pathname === '/company' ? 'active' : ''
        }`}
        onClick={() => navigate('/company')}
      >
        <img src={workIcon} alt='work' />
      </div>
      <div
        className={`surety-icon ${
          params.pathname === '/sureties' ? 'active' : ''
        }`}
        onClick={() => navigate('/sureties')}
      >
        <img src={envelopeIcon} alt='sureties' />
      </div>
    </div>
  );
};

export default Sidebar;
