import { lazy } from "react";
const Login = lazy(async () => await import("pages/Login/Login"));
const NotFound = lazy(async () => await import("pages/NotFound/NotFound"));
const Company = lazy(async () => await import("pages/Company/Company"));
const Home = lazy(async () => await import("pages/Home/Home"));
const Entries = lazy(async () => await import("pages/Entries/Entries"));
const Sureties = lazy(async () => await import("pages/Sureties/Sureties"));
const Otp = lazy(async () => await import("pages/Otp/Otp"));

export const routes = [
  {
    path: "*",
    name: "NotFound",
    exact: false,
    component: NotFound,
    protected: true,
  },
  {
    path: "/",
    name: "Home",
    exact: true,
    component: Home,
    protected: true,
  },
  {
    path: "/sureties",
    name: "Sureties",
    exact: true,
    component: Sureties,
    protected: true,
  },
  {
    path: "/:id",
    name: "Entries",
    component: Entries,
    protected: true,
  },
  {
    path: "/company",
    name: "Company",
    exact: true,
    component: Company,
    protected: true,
  },
  {
    path: "/login",
    name: "Login",
    exact: true,
    component: Login,
    protected: false,
  },
  {
    path: "/validate-otp",
    name: "ValidateOtp",
    exact: true,
    component: Otp,
    protected: false,
  },
];

export default routes;
