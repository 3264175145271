import { callAPI as api } from "./callApi";

const BASE_ROUTE = "/api";

export const login = async (body) => {
  try {
    const response = await api(`${BASE_ROUTE}/auth`, "POST", body);
    if (response && response.data) {
      const { exists } = response.data;
      if (exists) {
        localStorage.setItem(
          "user",
          JSON.stringify({ email: body.email, exists })
        );
      }
      return response.data;
    } else
      return { error: "Something went wrong. Please try again!", data: [] };
  } catch (err) {
    return err;
  }
};

export const otpLogin = async (body) => {
  try {
    const response = await api(`${BASE_ROUTE}/auth/validate-otp`, "POST", body);
    if (response && response.data) {
      const { token, name } = response.data;
      if (token) {
        localStorage.setItem("token", token);
        localStorage.setItem("name", name);
      }
      return response.data;
    } else
      return { error: "Something went wrong. Please try again!", data: [] };
  } catch (err) {
    return err;
  }
};

export const validate = async (token) => {
  const response = await api(`${BASE_ROUTE}/auth/validate`, "POST", token);
  return response;
};

export const getLocations = async () => {
  try {
    const response = await api(`${BASE_ROUTE}/locations`, "GET");
    if (response && response.data) {
      const { data } = response.data;
      return data;
    } else return null;
  } catch (err) {
    return err;
  }
};

export const getLocation = async (id) => {
  try {
    const response = await api(`${BASE_ROUTE}/locations/${id}`, "GET");
    if (response && response.data) {
      const { data } = response.data;
      return data;
    } else return null;
  } catch (err) {
    return err;
  }
};

export const addLocation = async (body) => {
  try {
    const response = await api(`${BASE_ROUTE}/locations`, "POST", body);
    if (response && response.data) {
      const { data } = response.data;
      return data;
    } else return null;
  } catch (err) {
    return err;
  }
};

export const updateLocation = async (body, id) => {
  try {
    const response = await api(`${BASE_ROUTE}/locations/${id}`, "PUT", body);
    if (response && response.data) {
      const { data } = response.data;
      return data;
    } else return null;
  } catch (err) {
    return err;
  }
};

export const deleteLocation = async (id) => {
  try {
    const response = await api(`${BASE_ROUTE}/locations/${id}`, "DELETE");
    if (response) {
      return response;
    } else return null;
  } catch (err) {
    return err;
  }
};

export const getCompanies = async () => {
  try {
    const response = await api(`${BASE_ROUTE}/companies`, "GET");
    if (response && response.data) {
      const { data } = response.data;
      return data;
    } else return null;
  } catch (err) {
    return err;
  }
};

export const addCompany = async (body) => {
  try {
    const response = await api(`${BASE_ROUTE}/companies`, "POST", body);
    if (response && response.data) {
      const { data } = response.data;
      return data;
    } else return null;
  } catch (err) {
    return err;
  }
};

export const updateCompany = async (body, id) => {
  try {
    const response = await api(`${BASE_ROUTE}/companies/${id}`, "PUT", body);
    if (response && response.data) {
      const { data } = response.data;
      return data;
    } else return null;
  } catch (err) {
    return err;
  }
};

export const deleteCompany = async (id) => {
  try {
    const response = await api(`${BASE_ROUTE}/companies/${id}`, "DELETE");
    if (response) {
      return response;
    } else return null;
  } catch (err) {
    return err;
  }
};

export const getLocationEntries = async (id) => {
  try {
    const response = await api(`${BASE_ROUTE}/entries/location/${id}`, "GET");
    if (response && response.data) {
      const { data } = response.data;
      return data;
    } else return null;
  } catch (err) {
    return err;
  }
};

export const addEntry = async (body) => {
  try {
    const response = await api(`${BASE_ROUTE}/entries`, "POST", body);
    if (response && response.data) {
      const { data } = response.data;
      return data;
    } else return null;
  } catch (err) {
    return err;
  }
};

export const updateEntry = async (body, id) => {
  try {
    const response = await api(`${BASE_ROUTE}/entries/${id}`, "PUT", body);
    if (response && response.data) {
      const { data } = response.data;
      return data;
    } else return null;
  } catch (err) {
    return err;
  }
};

export const deleteEntry = async (id) => {
  try {
    const response = await api(`${BASE_ROUTE}/entries/${id}`, "DELETE");
    if (response) {
      return response;
    } else return null;
  } catch (err) {
    return err;
  }
};

export const getFile = async (name) => {
  try {
    const response = await api(`${BASE_ROUTE}/entries/file/${name}`, "GET");
    if (response) {
      return response;
    } else return null;
  } catch (err) {
    return err;
  }
};

export const addSurety = async (body) => {
  try {
    const response = await api(`${BASE_ROUTE}/sureties`, "POST", body);
    if (response && response.data) {
      const { data } = response.data;
      return data;
    } else return null;
  } catch (err) {
    return err;
  }
};

export const updateSurety = async (body, id) => {
  try {
    const response = await api(`${BASE_ROUTE}/sureties/${id}`, "PUT", body);
    if (response && response.data) {
      const { data } = response.data;
      return data;
    } else return null;
  } catch (err) {
    return err;
  }
};

export const deleteSurety = async (id) => {
  try {
    const response = await api(`${BASE_ROUTE}/sureties/${id}`, "DELETE");
    if (response) {
      return response;
    } else return null;
  } catch (err) {
    return err;
  }
};

export const getSureties = async () => {
  try {
    const response = await api(`${BASE_ROUTE}/sureties`, "GET");
    if (response && response.data) {
      const { data } = response.data;
      return data;
    } else return null;
  } catch (err) {
    return err;
  }
};

export const getSuretyFile = async (name) => {
  try {
    const response = await api(`${BASE_ROUTE}/sureties/file/${name}`, "GET");
    if (response) {
      return response;
    } else return null;
  } catch (err) {
    return err;
  }
};
